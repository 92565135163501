<template>
  <div>
    <div class="partiy_box">
      <p class="partiy"><slot name="title"></slot></p>
      <div class="row">
        <div class="small_text row">
          <div class="small_text_box">
            <p>Landlord: <b>{{$store.state.lease.owner_first_name}} {{$store.state.lease.owner_last_name}}</b></p>
          </div>
          <div  class="small_text_box">
            <p>Tenant: <b>{{$store.state.lease.tenant_first_name}} {{$store.state.lease.tenant_last_name}}</b></p>
          </div>
          <div  class="small_text_box">
            <p>Leasing Agent: <b>{{$store.state.lease.user_first_name}} {{$store.state.lease.user_last_name}}</b></p>
          </div>
        </div>
        <slot name="btn"></slot>
      </div>
      <div class="small_text row">
          <div  class="small_text_box">
            <p>Arrival:  <b>{{$store.state.lease.arrival_date | dateFormat}}</b></p>
          </div>
          <div  class="small_text_box">
            <p>Departure：<b>{{$store.state.lease.departure_date | dateFormat}}</b></p>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>
<style lang="scss" scoped>
.partiy_box {
  .partiy {
    font-family: "Roboto-Bold", sans-serif;
    margin: 20px 0 0 0;
    font-size: 20px;
    text-align: left;
    color: #36425d;
  }
 .row{
   justify-content: space-between;
   align-items: flex-end;
 }
  .small_text {
    justify-content: flex-start;
    text-align: left;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    div {
      font-family: "Roboto-Regular", sans-serif;
      color: #38425b;
      font-size: 14px;
      width: 300px;
      b {
        font-family: "Roboto-Bold", sans-serif;
      }
    }
  }
}
</style>
<template>
  <div class="ly-tab">
    <ly-tabbar v-model="selectedId" v-bind="options">
      <ly-tab-item>
        <div class="exterior">
          <router-link class="sr_fo row"  exact :to="$route.params.id ? '/lease/' + $route.params.id : '/lease'">
            <span>Lease Details</span>
            <i v-if="$store.state.leaseDetail === true" class="el-icon-success"></i>
            <img class="img" v-else :src="loading" alt="" />
          </router-link>
        </div>
      </ly-tab-item>
      <ly-tab-item>
        <div class="exterior">
          <router-link class="sr_fo row"   exact :to="'/lease/' + $route.params.id + '/payment'" >
            <span>Payments</span>
            <i v-if="$store.state.leaseDetail === true" class="el-icon-success" ></i>
            <img class="img" v-else :src="loading" alt="" />
          </router-link>
        </div>
      </ly-tab-item>
      <ly-tab-item>
        <div class="exterior">
          <router-link class="sr_fo row"   exact :to="'/lease/' + $route.params.id + '/agreement'"  ><span>Lease Agreement</span>
            <i v-if="$store.state.leaseAgeent === true" class="el-icon-success" ></i>
            <img class="img" v-else :src="loading" alt="" />
          </router-link>
        </div>
      </ly-tab-item>
      <ly-tab-item>
        <div class="exterior">
          <router-link class="sr_fo row"  exact :to="'/lease/' + $route.params.id + '/security-deposit'" >
            <span>Security Deposit</span>
            <i v-if="$store.state.leaseSecurityDeposit === true" class="el-icon-success"></i><img class="img" v-else :src="loading" alt=""/>
            </router-link>
        </div>
      </ly-tab-item>
      <ly-tab-item>
        <div class="exterior">
          <div class="sr_fo row" @click="jumpPaymentReport">Payment Report</div>
        </div>
      </ly-tab-item>
    </ly-tabbar>
  </div>
</template>

<script>
import LyTabbar from "../../packages/tabbar";
import LyTabItem from "../../packages/tab-item";

export default {
  name: "LyTab",
  components: {
    LyTabbar,
    LyTabItem,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: require("../../../assets/icon/ico-progress.svg"),
      loaded: require("../../../assets/icon/ico-unselected.svg"),
      selectedId: this.value,
    };
  },
  computed: {
    labelKey() {
      return this.options.labelKey || "label";
    },
  },
  watch: {
    value(value) {
      this.selectedId = value;
    },
    selectedId(value) {
      this.$emit("input", value);
      this.$emit("change", this.items[value], value);
    },
  },
  methods: {
    jumpPaymentReport() {
      this.$router.push({
        path: "/lease-report",
        query: { lid: this.$route.params.id },
      });
    },
  },
};
</script>

<style  lang="scss" scoped>
a {
  text-decoration: none;
}
.exterior {
  position: relative;
  border-left: 1px solid rgb(233, 229, 229);
}
.sr_fo {
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: left;
  font-size: 14px;
  font-family: "Roboto-Bold", sans-serif;
  color: #404b66;
  padding: 0 20px;
  i {
    color: #678993;
    font-size: 18px;
  }
  .img {
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
}
.router-link-active .img {
  display: block;
}
/* 导航栏激活 */
.router-link-active {
  color: #678993;
  background-color: #f4f9fc;
  &::after {
    content: "";
    text-align: center;
    position: absolute;
    left: 7px;
    height: 91%;
    transform: translateX(-180%);
    border-left: 4px solid #678993;
  }
}
</style>

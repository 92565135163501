<template>
  <div class="aside_right">
    <div class="aside-title row">
      <div>
        <span> {{ title }}</span>
        <TopInfor class="pc" v-show="$route.params.id"></TopInfor>
      </div>
      <div>
        <a class="unblock_date" v-if=" $route.params.id && (type === 'admin'||isType===true) && $store.state.lease.is_block_date " @click="$refs.delUnblockDate.show = true" >Unblock Dates</a >
        <a class="unblock_date"  v-if=" $route.params.id && (type === 'admin'||isType===true) && !$store.state.lease.is_block_date "  @click="$refs.delBlockDate.show = true" >Block Dates</a >
        <span class="cancel-lease" v-if="$route.params.id && $store.state.lease.status != 'Cancelled'" @click="$refs.showCancelDialog.show = true" >Cancel Lease</span >
      </div>
    </div>
    <el-divider></el-divider>
    <Lease-Move-Navigation class="move"></Lease-Move-Navigation>
    <TopInfor class="move move-infor" v-show="$route.params.id"></TopInfor>
    <div class="row">
      <div class="pc">
        <div class="router_link">
          <div class="exterior">
            <router-link class="sr_fo row"  exact :to="$route.params.id ? '/lease/' + $route.params.id : '/lease'" >
              <span>Lease Details</span>
              <i v-if="$store.state.leaseDetail === true" class="el-icon-success" ></i>
              <img class="img" v-else :src="loading" alt="" />
            </router-link>
          </div>
          <div class="exterior">
            <router-link class="sr_fo row"  exact :to="'/lease/' + $route.params.id + '/payment'" ><span>Payments</span>
              <i v-if="$store.state.leaseDetail === true" class="el-icon-success" ></i><img class="img" v-else :src="loading" alt="" />
            </router-link>
          </div>
          <div class="exterior">
            <router-link class="sr_fo row"  exact :to="'/lease/' + $route.params.id + '/agreement'" ><span>Lease Agreement</span>
              <i v-if="$store.state.leaseAgeent === true" class="el-icon-success" ></i ><img class="img" v-else :src="loading" alt="" />
            </router-link>
          </div>
          <div class="exterior">
            <router-link class="sr_fo row"  exact :to="'/lease/' + $route.params.id + '/security-deposit'" ><span>Security Deposit</span ><i  v-if="$store.state.leaseSecurityDeposit === true" class="el-icon-success" ></i ><img class="img" v-else :src="loading" alt="" /></router-link>
          </div>
          <div class="exterior" v-if="$route.params.id">
            <div class="sr_fo row" @click="jumpPaymentReport">
              Payment Report
            </div>
          </div>
        </div>
      </div>
      <div class="aside-router">
        <!-- <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" :newDatas="data" @createNewLease="createNewLease" />
        </keep-alive>
      </router-view> -->
        <router-view :newDatas="data"></router-view>
      </div>
    </div>
    <!-- 解锁 -->
    <Del-Dialog ref="delUnblockDate" :title="titleDel" @del="unblockDate" ></Del-Dialog>
    <!-- 锁住日期 -->
    <Del-Dialog ref="delBlockDate" :title="titleBlock" @del="blockDate" ></Del-Dialog>
    <!-- cancel lease弹出框 -->
    <Cancel-Dialog ref="showCancelDialog" @refresh="refresh" @createNewLease="createNewLease"></Cancel-Dialog>
  </div>
</template>
<script>
import CancelDialog from "./LeaseCancelDialog";
import TopInfor from "./LeaseTopInfor";
import storage from "../../../js/storage";
import { apiCreateUnblockDate, apiCreateBlockDate } from "../../../API/api";
import DelDialog from "../../common/DelDialog";
import LeaseMoveNavigation from "./LeaseMoveNavigation";
export default {
  components: { CancelDialog, TopInfor, DelDialog,LeaseMoveNavigation },
  data() {
    return {
      show: false,
      title: "Create New Lease",
      loading: require("../../../assets/icon/ico-progress.svg"),
      loaded: require("../../../assets/icon/ico-unselected.svg"),
      type: "",
      titleDel: "Do you want to unlock this date?",
      titleBlock: "Do you want to lock this date?",
      selectedId: 0,
      items: [
        { label: "Lease Details" },
        { label: "Payments" },
        { label: "Lease Agreement" },
        { label: "Security Deposit" },
        { label: "Payment Report" },
      ],
      options: {
        activeColor: "#1d98bd",
      },
      isType: false,
      data:{},
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (
      (to.name === "lease-payment" ||
        to.name === "lease-agreement" ||
        to.name === "lease-security-deposit") &&
      (!to.params.id || to.params.id === "undefined")
    ) {
      this.$message.error("Please upload the lease information first");
      next("/lease");
    } else {
      next();
    }
  },
  methods: {
    createNewLease(item) {
      this.$router.push({ name: "create-lease" });
      this.data = item;
    },
    flushCom() {
      this.show = false;
    },
    refresh(id) {
      this.$router.push({ name: "edit-lease", params: { id: id } });
      setTimeout(() => {
        this.$router.go(0);
      }, 200);
    },
    // 取消阻止日期
    unblockDate() {
      this.$refs.delUnblockDate.loading = true;
      apiCreateUnblockDate({ lease: this.$route.params.id })
        .then((res) => {
          // this.$message.success("Success")
          this.$router.go(0);
        })
        .finally(() => {
          this.$refs.delUnblockDate.show = false;
          this.$refs.delUnblockDate.loading = false;
        });
    },
    // 锁定日期
    blockDate() {
      this.$refs.delBlockDate.loading = true;
      apiCreateBlockDate({ lease: this.$route.params.id })
        .then((res) => {
          // this.$message.success("Success")
          this.$router.go(0);
        })
        .finally(() => {
          this.$refs.delBlockDate.show = false;
          this.$refs.delBlockDate.loading = false;
        });
    },
    jumpPaymentReport() {
      this.$router.push({
        path: "/lease-report",
        query: { lid: this.$route.params.id },
      });
    },
  },
  created() {
    if (this.$route.params.id) {
      this.title = "Lease #" + this.$route.params.id;
    } else {
      this.title = "Create New Lease";
    }
    this.isType = storage.getItem("isType")

  },
  watch: {
    "$route.params.id"(newV) {
      if (newV) {
        this.title = "Lease #" + this.$route.params.id;
      } else {
        this.title = "Create New Lease";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.aside_right {
  background-color: #fff;
  a {
    text-decoration: none;
  }
  .row {
    align-items: flex-start;
  }
  .aside-router {
    border-left: 1px solid #e1e9ef;
    padding: 0px;
    width: calc(100% - 250px);
  }

  .aside-title {
    text-align: left;
    font-size: 20px;
    font-family: "Roboto-Bold", sans-serif;
    color: #36425d;
    padding: 20px;
    justify-content: space-between;
    span:hover {
      cursor: pointer;
    }
    .cancel-lease {
      color: #de1b4b;
      font-size: 14px;
      font-family: "Roboto-Medium", sans-serif;
    }
    .unblock_date {
      text-decoration: underline;
      color: #005ff7;
      font-size: 14px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  /deep/ .el-divider--horizontal {
    margin: 0;
  }
  .router_link {
    .exterior {
      position: relative;
    }
    .sr_fo {
      align-items: center;
      justify-content: space-between;
      width: 250px;
      height: 50px;
      line-height: 50px;
      text-align: left;
      font-size: 14px;
      font-family: "Roboto-Bold", sans-serif;
      color: #404b66;
      padding: 0 20px;
      i {
        color: #678993;
        font-size: 18px;
      }
      .img {
        display: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .router-link-active .img {
      display: block;
    }
  }
  /* 导航栏激活 */
  .router-link-active {
    color: #678993;
    background-color: #f4f9fc;
    &::after {
      content: "";
      text-align: center;
      position: absolute;
      left: 7px;
      height: 91%;
      transform: translateX(-180%);
      border-left: 4px solid #678993;
    }
  }
  .move {
    display: none;
  }
  .move-infor {padding: 0 20px;}
  @media (max-width: 992px) {
  .aside-router {width:100%;}
    .move {
      display: block;
    }
    .pc {
      display: none;
    }
  }
}
</style>
